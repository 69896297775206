// consumer_flight_report_OperateReportForExcel 机票运行报表导出
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/haolv-biz/%E6%9C%BA%E7%A5%A8%20-%20%E6%8A%A5%E8%A1%A8/flightOrderReportOfOperatForExcelUsingPOST
const consumer_flight_report_OperateReportForExcel = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/report/OperateReportForExcel',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_report_OperateReportForExcel;