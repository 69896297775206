import Pagination from '@/component/pagination/index.vue'
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue'
import consumer_flight_report_OperateReport from '@/lib/data-service/haolv-default/consumer_flight_report_OperateReport'
import consumer_flight_report_OperateReportForExcel from '@/lib/data-service/haolv-default/consumer_flight_report_OperateReportForExcel' // 导出
export default {
    data () {
        return {
            loading: false,
            params: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                query: {
                    orderNo: '',
                    supplierOrderNo: '',
                    companyName: '',
                    realName: '',
                    orderTime: '',
                    paymentChannel: '',
                    psgName: '',
                    flight: '',
                    depTime: '',
                    arrTime: '',
                    depAirportName: '',
                    arrAirportName: '',
                }
            },
            payList: [
                {value: '', label: '所有'},
                {value: 'ALIPAY', label: '支付宝支付'},
                {value: 'WECHAT', label: '微信支付'},
                {value: 'YEEPAY', label: '易宝支付'},
                {value: 'CREDIT', label: '授信支付'},
                {value: 'UNIONPAY', label: '网银支付'},
                {value: 'B2B_CHINAPAY', label: '企业银联支付'},
                {value: 'B2C_CHINAPAY', label: '个人银联支付'},
                {value: 'PERSON_UNIONPAY', label: '个人网银支付'},
                {value: 'CREDIT_UNIONPAY', label: '个人信用卡网银支付'},
                {value: 'YPAY', label: '胤钱宝支付'},
            ],
            list: [],
            fileUrl: '',
            canSubmit: true,
            flightPickerOptions: {
                disabledDate: this.flightDisabledDate
            },
            flightEndPickerOptions: {
                disabledDate: this.flightEndDisabledDate
            },
        }
    },
    components: {Pagination},
    created () {},
    mounted () {},
    activated () {
        this.reset()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        flightDisabledDate(time) {
            if (this.params.query.arrTime === '' || this.params.query.arrTime === null) {
                return false
            } else {
                return time.getTime() > (new Date(this.params.query.arrTime).getTime() - 8.64e7)
            }
        },
        flightEndDisabledDate(time) {
            if (this.params.query.depTime === '' || this.params.query.depTime === null) {
                return false
            } else {
                return time.getTime() < (new Date(this.params.query.depTime).getTime() - 8.64e7)
            }
        },
        search() {
            this.params.currentPage = 1
            this.getList()
        },
        reset() {
            this.params = {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                query: {
                    orderNo: '',
                    supplierOrderNo: '',
                    companyName: '',
                    realName: '',
                    orderTime: '',
                    paymentChannel: '',
                    psgName: '',
                    flight: '',
                    depTime: '',
                    arrTime: '',
                    depAirportName: '',
                    arrAirportName: '',
                }
            }
            this.search()
        },
        getList() {
            this.loading = true
            // 后端不做判空处理，所以query的字段为空时，不传
            let params = JSON.parse(JSON.stringify(this.params))
            let query = {}
            if (params.query.orderNo !== '') {
                query.orderNo = params.query.orderNo
            }
            if (params.query.supplierOrderNo !== '') {
                query.supplierOrderNo = params.query.supplierOrderNo
            }
            if (params.query.companyName !== '') {
                query.companyName = params.query.companyName
            }
            if (params.query.realName !== '') {
                query.realName = params.query.realName
            }
            if (params.query.orderTime !== '') {
                query.orderTime = params.query.orderTime
            }
            if (params.query.paymentChannel !== '') {
                query.paymentChannel = params.query.paymentChannel
            }
            if (params.query.psgName !== '') {
                query.psgName = params.query.psgName
            }
            if (params.query.flight !== '') {
                query.flight = params.query.flight
            }
            if (params.query.depTime !== '') {
                query.depTime = params.query.depTime
            }
            if (params.query.arrTime !== '') {
                query.arrTime = params.query.arrTime
            }
            if (params.query.depAirportName !== '') {
                query.depAirportName = params.query.depAirportName
            }
            if (params.query.arrAirportName !== '') {
                query.arrAirportName = params.query.arrAirportName
            }
            params.query = query
            consumer_flight_report_OperateReport(params).then(res => {
                this.loading = false
                this.list = res.datas.records
                this.params.total = res.datas.total
            }).catch(() => {
                this.loading = false
                this.list = []
                this.params.total = 0
            })
        },
        toPreview() {
            let params = JSON.parse(JSON.stringify(this.params))
            if (!this.canSubmit) {
                return
            }
            // 后端不做判空处理，所以query的字段为空时，不传
            let query = {}
            if (params.query.orderNo !== '') {
                query.orderNo = params.query.orderNo
            }
            if (params.query.supplierOrderNo !== '') {
                query.supplierOrderNo = params.query.supplierOrderNo
            }
            if (params.query.companyName !== '') {
                query.companyName = params.query.companyName
            }
            if (params.query.realName !== '') {
                query.realName = params.query.realName
            }
            if (params.query.orderTime !== '') {
                query.orderTime = params.query.orderTime
            }
            if (params.query.paymentChannel !== '') {
                query.paymentChannel = params.query.paymentChannel
            }
            if (params.query.psgName !== '') {
                query.psgName = params.query.psgName
            }
            if (params.query.flight !== '') {
                query.flight = params.query.flight
            }
            if (params.query.depTime !== '') {
                query.depTime = params.query.depTime
            }
            if (params.query.arrTime !== '') {
                query.arrTime = params.query.arrTime
            }
            if (params.query.depAirportName !== '') {
                query.depAirportName = params.query.depAirportName
            }
            if (params.query.arrAirportName !== '') {
                query.arrAirportName = params.query.arrAirportName
            }
            params.query = query
            consumer_flight_report_OperateReportForExcel(params).then(res => {
                this.canSubmit = true
                this.fileUrl = 'http://' + res.datas
                this.$nextTick(() => {
                    this.$refs.downloadBtn.click()
                })
            }).catch(() => {
                this.canSubmit = true
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
